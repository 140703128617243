import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'

// Pages
import About from  '../views/About.vue'
import Portfolio from '../views/Portfolio.vue'

// Sub pages
import Simple from '../components/Portfolio/Simple.vue'
import Mind from '../components/Portfolio/Mind.vue'
import Creating from  '../components/Portfolio/Creating.vue'
import Complexity from '../components/Portfolio/Complexity.vue'




const routes = [{
  path: '/',
  name: 'home',
  component: Home
},
{
  path: '/about',
  name: 'about',
  component: About
},
{
  path: '/portfolio/',
  name: '',
  component: Portfolio,
  children: [{
      path: '',
      redirect: 'simple'
    },
    {
      path: 'simple',
      name: 'simple',
      component: Simple
    },
    {
      path: 'mind',
      name: 'mind',
      component: Mind
    },
    {
      path: 'creating',
      name: 'creating',
      component: Creating
    },
    {
      path: 'complexity',
      name: 'complexity',
      component: Complexity
    }
  ]
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
