<template>
  <div class="Mind">
    <div class="container">
      <div class="logo2">
        <div class="frame">
          <div v-for="(row, rowIndex) in prosList" :key="rowIndex" class="text">
            <div
              v-for="i in 2"
              :key="i"
              :class="{'half' : true, 'reverse': (rowIndex % 2 === 0) ? false : true}"
            >
              <span v-for="(word, wordIndex) in row" :key="wordIndex" class="word">{{ word }}</span>
            </div>
          </div>
        </div>

        <div class="frame">
          <div v-for="(row, rowIndex) in consList" :key="rowIndex" class="text">
            <div
              v-for="i in 2"
              :key="i"
              :class="{'half' : true, 'reverse': (rowIndex % 2 !== 0) ? false : true}"
            >
              <span v-for="(word, wordIndex) in row" :key="wordIndex" class="word">{{ word }}</span>
            </div>
          </div>
        </div>
        <div class="brain"></div>
      </div>
    </div>

    <div class="content">
      <p class="text">
        Just a
        <span style="font-weight:300;color:#ffca3a">simple </span>
        <span style="font-weight:300;color:#944bbb">mind </span>
        <span style="font-weight:300;color:#ffca3a">creating </span>
        <span style="font-weight:300;color:#944bbb">complexity </span>
        currently located on Earth, precisely in France, near Paris.
        <br>
        Aspiring to greatness, knowledge thirsting, mostly lazying around though, eclectic & chaotically organised.
        <br>
        I develop, I design, I create, I am a Jack of all trades - Master of none, an all-rounder, a fullstack. (Kinda, this just sounds fancy more than anything)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prosList: [
        ["LAZY", "ORDERED", "CHAOTIC"],
        ["PATIENT", "CREATIVE", "PERFECTIONIST"],
        ["DISCREET", "CURIOUS", "GAMER"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["GEEK", "NERD", "OTAKU"],
        ["MUSIC", "TECHNOLOGY", "ART"],
        ["SERIOUS", "SIMPLE", "COMPLEX"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
        ["DISCREET", "CURIOUS", "NERD"],
        ["DISCREET", "CURIOUS", "NERD"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
      ],
      consList: [
        ["LAZY", "ORDERED", "CHAOTIC"],
        ["PATIENT", "CREATIVE", "PERFECTIONIST"],
        ["DISCREET", "CURIOUS", "GAMER"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["GEEK", "NERD", "OTAKU"],
        ["MUSIC", "TECHNOLOGY", "ART"],
        ["SERIOUS", "SIMPLE", "COMPLEX"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
        ["DISCREET", "CURIOUS", "NERD"],
        ["DISCREET", "CURIOUS", "NERD"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["DEPENDABLE", "HELPFUL", "CHEERFUL"],
        ["ECLECTIC", "DREAMER", "FOCUSED"],
      ]
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.Mind {
  position: absolute;
  height: 100%;
  width: 100%;

  .container {
    position: relative;
    height: 350px;
    width: 100%;

    .logo2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      height: 320px;
      width: 320px;

      .brain {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        background-image: url("../../assets/mind/MindMask3.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .frame {
        overflow: hidden;
        height: 99%;
        width: 50%;
        background-color: #944bbb;

        .text {
          display: flex;
          width: 100%;

          .half {
            animation: infiniteXScroll 25s linear infinite;
            line-height: 120%;

            &.reverse {
              animation: infiniteXScroll 25s linear infinite reverse;
            }

            .word {
              margin: 0 2px;
              letter-spacing: 1px;
              font-size: 28px;
              font-weight: bold;
              color: #ffca3a;
            }
          }
        }
      }
    }
  }

  .content {
    // border: 1px solid red;
    padding-bottom: 48px;

    .text {
      margin: 0 auto;
      padding: 0 16px;
      line-height: 48px;
      font-size: 24px;
      font-weight: 300;
      color: #f5f1ed;
    }
  }
}

@keyframes infiniteXScroll {
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 767px) {
  .Mind {
    overflow: auto;
    flex-wrap: wrap;
    .container {
      .logo {
      }
    }

    .headings {
      width: 100%;
    }
  }
}
</style>