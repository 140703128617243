<template>
  <div class="Complexity">
    <h1>{{ $route.name }}</h1>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">

</style>