<template>
  <div class="Simple">
    <div class="container">
      <div class="logo">
        <div class="anima"></div>
      </div>
    </div>

    <div class="content">
      <div class="headings">
        <h4 class="class">Creator</h4>
        <h3 class="title">Developer & Designer</h3>
        <h1 class="firstName">Jonathan</h1>
        <h2 class="lastName">Co Kim Len</h2>
        <h4 class="location">from Earth</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function() {
    var logo = document.querySelector(".logo");

    function changecolor() {
      var backgroundPos =
        Math.floor(Math.random() * 100) +
        "px " +
        Math.floor(Math.random() * 100) +
        "px, " +
        Math.floor(Math.random() * 100) +
        "px " +
        Math.floor(Math.random() * 100) +
        "px, " +
        Math.floor(Math.random() * 100) +
        "px " +
        Math.floor(Math.random() * 100) +
        "px";
      logo.style.backgroundPosition = backgroundPos;
    }
    setTimeout(changecolor, 200);
    setInterval(changecolor, 4000);
  },
  methods: {}
};
</script>

<style lang="scss">
.Simple {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .container {
    position: relative;
    height: 350px;
    width: 100%;
    opacity: 0;
    animation: fadeIn 1.2s ease-in forwards;

    .logo {
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 320px;
      width: 320px;
      background-image: url("../../assets/simple/bg1.png"),
        url("../../assets/simple/bg2.png"), url("../../assets/simple/bg3.png");
      background-position: 50% 50%;
      background-blend-mode: hard-light, difference, normal;
      background-size: contain;
      transition: background-position 4s linear;

      &::after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 240px solid #252323;
        border-radius: 75%;
        height: 150%;
        width: 150%;
        animation: decreaseBorderRadius 1.2s ease-in forwards;
      }

      .anima {
        height: 100%;
        width: 100%;
        background-image: url("../../assets/simple/LogoAnimaReworkMask-03-03.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .content {
    height: calc(100% - 350px);
    width: 100%;

    .headings {
      position: relative;
      margin: 0 auto;
      width: fit-content;
      max-width: 360px;
      text-align: right;
      opacity: 1;
            animation: fadeIn 1.2s ease-in forwards;
      animation-delay: 1.2s;

      .class {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        color: #383737;
        text-align: center;
        line-height: 18px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
          height: 14px;
          width: 105%;
          background-color: #252323;
          animation: collapseHeight 1.2s ease-in-out forwards;
          animation-delay: 3s;
        }
      }

      .title {
        position: relative;
        font-size: 32px;
        font-weight: bold;
        color: #383737;
        line-height: 19px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
          height: 32px;
          width: 105%;
          background-color: #252323;
          animation: collapseHeightAndGrowWidth 1.2s ease-in-out forwards;
          animation-delay: 2.4s;
        }
      }

      .firstName {
        position: relative;
        font-size: 64px;
        font-weight: bold;
        color: #c20114;
        line-height: 50px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
          height: 56px;
          width: 105%;
          background-color: #252323;
          animation: collapseHeight 1.2s ease-in-out forwards;
          animation-delay: 1.2s;
        }
      }

      .lastName {
        position: relative;
        font-size: 32px;
        font-weight: bold;
        color: #c20114;
        line-height: 22px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
          height: 28px;
          width: 105%;
          background-color: #252323;
          animation: collapseHeight 1.2s ease-in-out forwards;
          animation-delay: 1.8s;
        }
      }

      .location {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        color: #383737;
        line-height: 16px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
          height: 16px;
          width: 105%;
          background-color: #252323;
          animation: collapseHeight 1.2s ease-in-out forwards;
          animation-delay: 3.6s;
        }
      }
    }
  }

  .content {
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes decreaseBorderRadius {
  100% {
    border: 0px solid #252323;
  }
}

// Title line-through width grow
@keyframes collapseHeightAndGrowWidth {
  75% {
    height: 0px;
  }
  100% {
    height: 4px;
    width: 105%;
  }
}

@keyframes collapseHeight {
  100% {
    height: 0px;
  }
}
</style>