<template>
  <div class="Portfolio">

    <div v-if="mobile" class="frame">
      <div class="items">
        <router-view v-slot="{ Component }">
          <transition :name="slideDirection ? 'slide-fade-up' : 'slide-fade-down'">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

      <div class="navigation">

        <transition name="fade">
          <div v-show="menu" @click="toggleNav" class="overlay"></div>
        </transition>
        <transition name="fade">
          <div v-show="menu" class="container">
            <router-link v-for="route in routes" :key="route.id" :to="route.path" class="route">
              <div
                @click="updateCurrentRoute(route.id)"
                :class="{'title': true, active: currentRouteId === route.id ? true : false}"
              >
                <div class="number">
                  <div v-for="(nb, index) in 3" :key="index" class="frame">
                    <div :class="{'item': true, 'shapeshift': shapeshift(route.id, index)}"></div>
                  </div>
                </div>
                <h1 class="text" :style="{'color': route.color}">{{ route.name }}</h1>
              </div>
            </router-link>
          </div>
        </transition>

        <div
          @click="toggleNav"
          class="button"
          :style="{'border-color': routes[currentRouteId].color}"
        >
          <div
            :class="{'upper':true, 'cross': menu, 'menu' : ((menu === '') ? false : !menu)}"
            :style="{'background-color': routes[currentRouteId].color}"
          ></div>
          <div
            :class="{'lower':true, 'cross': menu, 'menu' : ((menu === '') ? false : !menu)}"
            :style="{'background-color': routes[currentRouteId].color}"
          ></div>
        </div>

        <div class="social">
          <div class="link first"></div>
          <div class="link second"></div>
          <div class="link third"></div>
        </div>
      </div>
    </div>

    <div v-if="!mobile" class="content">
      <div class="navigation">
        <div class="chevrons">
          <div @click="updateCurrentRoute(currentRouteId - 1)" class="top">
            <div class="polygon"></div>
          </div>
          <div @click="updateCurrentRoute(0)" class="middle">
            <div class="polygon"></div>
          </div>
          <div @click="updateCurrentRoute(currentRouteId + 1)" class="bottom">
            <div class="polygon"></div>
          </div>
        </div>

        <div class="container">
          <router-link v-for="route in routes" :key="route.id" :to="route.path" class="route">
            <div
              @click="updateCurrentRoute(route.id)"
              :class="{'title': true, active: currentRouteId === route.id ? true : false}"
            >
              <div class="number">
                <div v-for="(nb, index) in 3" :key="index" class="frame">
                  <div :class="{'item': true, 'shapeshift': shapeshift(route.id, index)}"></div>
                </div>
              </div>
              <h1 class="text" :style="{'color': route.color}">{{ route.name }}</h1>
            </div>
          </router-link>
        </div>

        <div class="social">
          <div class="link"></div>
          <div class="link"></div>
          <div class="link"></div>
        </div>
      </div>


      <div class="items">
        <router-view v-slot="{ Component }">
          <transition :name="slideDirection ? 'slide-fade-up' : 'slide-fade-down'">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "portfolio",
  components: {},
  data() {
    return {
      mobile: "",
      menu: "",
      slideDirection: 0,
      currentRouteId: 0,
      currentRouteCode: [0, 0, 1],
      routes: [
        {
          id: 0,
          name: "simple",
          path: "simple",
          color: "#FFCA3A",
          code: ""
        },
        {
          id: 1,
          name: "mind",
          path: "mind",
          color: "#944BBB",
          code: ""
        },
        {
          id: 2,
          name: "creating",
          path: "creating",
          color: "#FFCA3A",
          code: ""
        },
        {
          id: 3,
          name: "complexity",
          path: "complexity",
          color: "#944BBB",
          code: ""
        }
      ]
    };
  },
  created: function() {
    window.addEventListener("resize", this.isMobile);

    for (let i = 0; i < this.routes.length; i++) {
      if (this.routes[i].name === this.$route.name) {
        this.currentRouteId = i;
      }
    }
    // eslint-disable-next-line no-console
    window.addEventListener("wheel", event =>
    (event.deltaY > 0 ? this.updateCurrentRoute(this.currentRouteId + 1) : this.updateCurrentRoute(this.currentRouteId - 1))
    );
  },
  unmounted: function() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  watch: {
    currentRouteId: function() {
      let container = document.querySelector(".navigation .container");

      /* if (window.innerWidth <= 767) {
        container.style.top =
          "calc(50% - " + (17 + 28 * this.currentRouteId) + "px)";
      } else { */
      container.style.top =
        "calc(50% - " + (34 + 56 * this.currentRouteId) + "px)";
      /* } */
    }
  },
  computed: {},
  methods: {
    isMobile: function() {
      this.mobile = window.matchMedia("(max-width: 768px)").matches;
    },
    toggleNav: function() {
      this.menu = !this.menu;
    },
    updateRouteCode: function() {
      let result = [];
      let nb = this.currentRouteId + 1;

      for (let i = 0; i < 3; i++) {
        result.unshift(nb % 2);
        nb = Math.floor(nb / 2);
      }
      this.currentRouteCode = result;
    },
    shapeshift: function(routeId, itemId) {
      if (routeId === this.currentRouteId) {
        if (this.currentRouteCode[itemId] == "1") {
          return true;
        } else {
          return false;
        }
      }
    },
    updateCurrentRoute: function(routeId) {
      if (
        routeId < this.routes.length &&
        routeId >= 0 &&
        this.currentRouteId !== routeId
      ) {
        if (routeId > this.currentRouteId) {
          this.slideDirection = 0;
        } else if (routeId < this.currentRouteId) {
          this.slideDirection = 1;
        }
        this.$router.push(this.routes[routeId].path);
        this.currentRouteId = routeId;
        this.updateRouteCode();
      }
    },
    scrollUpdate: function(index) {
      setTimeout(function() {
        this.updateCurrentRoute(index);
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.test {
  height: 1px;
  width: 100%;
  border: 1px solid red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Portfolio {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: #252323;

  .header {
    height: 20%;
    width: 100%;
  }

  .frame {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    .navigation {
      position: fixed;
      bottom: 0;
      // border-right: 1px solid #383737;
      height: 50px;
      width: 100%;
      background-color: #252323;
      // animation: slideUp 0.8s ease-in forwards;

      /*       .chevrons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 60px;
        text-align: center;
        color: red;

        .top {
          height: 48px;
          width: 48px;
          cursor: pointer;

          .polygon {
            transform: translateX(50%);
            height: 100%;
            width: 50%;
            clip-path: polygon(0 100%, 50% 0, 100% 100%, 50% 65%);
            background-color: #f5f1ed;
            transition: transform 0.8s ease-out;

            &:hover {
              transform: translate(50%, -15%);
            }
          }
        }

        .middle {
          margin: 12px 0;
          height: 24px;
          width: 48px;

          cursor: pointer;

          .polygon {
            transform: translateX(50%);
            height: 100%;
            width: 50%;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            background-color: #f5f1ed;
            transition: transform 0.8s ease-out;
            &:hover {
              transform: translateX(50%) scale(1.2);
            }
          }
        }

        .bottom {
          height: 48px;
          width: 48px;
          cursor: pointer;

          .polygon {
            transform: translateX(50%);
            height: 100%;
            width: 50%;
            clip-path: polygon(0 0, 50% 100%, 100% 0, 50% 35%);
            background-color: #f5f1ed;
            transition: transform 0.8s ease-out;
            &:hover {
              transform: translate(50%, 15%);
            }
          }
        }
      } */

      .overlay {
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(37, 35, 35, 0.9);
      }

      .container {
        z-index: 2;
        position: fixed;
        top: calc(50% - 34px);
        left: 50%;
        height: fit-content;
        transform: translateX(-50%);
        transition: top 0.8s ease-in-out, opacity 0.8s;

        .route {
          text-decoration: none;

          .title {
            display: flex;
            margin: 12px 0;
            height: 44px;
            opacity: 0.3;
            transition: opacity 0.8s;

            &.active {
              opacity: 1;
            }

            .number {
              display: flex;

              .frame {
                margin: 0 2px;
                height: 100%;
                width: 24px;

                .item {
                  margin: 0 auto;
                  border: 6px solid #f5f1ed;
                  border-radius: 12px;
                  height: 100%;
                  width: 100%;
                  transition: border 0.8s ease-in-out, width 0.8s ease-in-out;
                }

                .shapeshift {
                  width: 0;
                  border: 3px solid #ffca3a;
                }
              }
            }

            .text {
              padding-left: 8px;
              width: 65%;
              line-height: 44px;
              text-align: left;
            }
          }
        }
      }

      .button {
        z-index: 1;
        position: absolute;
        bottom: -48px;
        left: 50%;
        transform: translateX(-50%);
        // border: 2px solid;
        // border-radius: 24px;
        height: 48px;
        width: 48px;
        transition: border-color 0.8s ease-in;
        animation: slideUp 1.2s ease-in forwards;
        animation-delay: 1.2s;

        .upper {
          position: absolute;
          left: 50%;
          top: 33%;
          transform: translate(-50%, -50%);
          border-radius: 1px;
          height: 2px;
          width: 36px;
          transition: background-color 0.8s ease-in;

          &.cross {
            animation: upperCross 0.8s ease-in forwards;
          }
          &.menu {
            animation: upperCrossReverse 0.8s ease-in forwards;
          }
        }

        .lower {
          position: absolute;
          left: 50%;
          top: 66%;
          transform: translate(-50%, -50%);
          border-radius: 1px;
          height: 2px;
          width: 36px;
          transition: background-color 0.8s ease-in;

          &.cross {
            animation: lowerCross 0.8s ease-in forwards;
          }
          &.menu {
            animation: lowerCrossReverse 0.8s ease-in forwards;
          }
        }
      }

      .social {
        z-index: 1;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 48px;
        width: 144px;

        .link {
          display: inline-block;
          position: absolute;
          border-radius: 16px;
          top: 110%;
          transform: translate(-50%, -50%) scale(0.1);
          height: 32px;
          width: 32px;
          background-color: #fff;

          &.first {
            left: 20%;
            animation: slideUpAndScale 1.2s cubic-bezier(0.17, 0.67, 0.83, 0.67)
              forwards;
            animation-delay: 1.8s;
          }

          &.second {
            left: 50%;
            animation: slideUpAndScale 1.2s cubic-bezier(0.17, 0.67, 0.83, 0.67)
              forwards;
            animation-delay: 2.4s;
          }

          &.third {
            left: 80%;
            animation: slideUpAndScale 1.2s cubic-bezier(0.17, 0.67, 0.83, 0.67)
              forwards;
            animation-delay: 3s;
          }
        }
      }
    }

    .items {
      display: flex;
      overflow: auto;
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .footer {
    position: relative;
    height: 20%;
    width: 100%;

    .social {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 15%;

      .link {
        border-radius: 16px;
        height: 32px;
        width: 32px;
        background-color: #fff;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .Portfolio {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    background-color: #252323;

    .header {
      height: 20%;
      width: 100%;
    }

    .content {
      display: flex;
      height: 100%;
      width: 100%;

      .navigation {
        overflow: hidden;
        position: relative;
        // display: flex;
        border-right: 1px solid #383737;
        height: 100%;
        width: 338px;

        /* &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 8px;
        width: 100%;
        background-image: linear-gradient(#252323, rgba(0, 0, 0, 0));
        z-index: 1;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        height: 8px;
        width: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), #252323);
        z-index: 1;
      } */

        .chevrons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 60px;
          text-align: center;
          color: red;

          .top {
            height: 48px;
            width: 48px;
            cursor: pointer;

            .polygon {
              transform: translateX(50%);
              height: 100%;
              width: 50%;
              clip-path: polygon(0 100%, 50% 0, 100% 100%, 50% 65%);
              background-color: #f5f1ed;
              transition: transform 0.8s ease-out;

              &:hover {
                transform: translate(50%, -15%);
              }
            }
          }

          .middle {
            margin: 12px 0;
            height: 24px;
            width: 48px;

            cursor: pointer;

            .polygon {
              transform: translateX(50%);
              height: 100%;
              width: 50%;
              clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
              background-color: #f5f1ed;
              transition: transform 0.8s ease-out;
              &:hover {
                transform: translateX(50%) scale(1.2);
              }
            }
          }

          .bottom {
            height: 48px;
            width: 48px;
            cursor: pointer;

            .polygon {
              transform: translateX(50%);
              height: 100%;
              width: 50%;
              clip-path: polygon(0 0, 50% 100%, 100% 0, 50% 35%);
              background-color: #f5f1ed;
              transition: transform 0.8s ease-out;
              &:hover {
                transform: translate(50%, 15%);
              }
            }
          }
        }

        .container {
          position: absolute;
          top: calc(50% - 34px);
          left: 60px;
          height: fit-content;
          transition: top 0.8s ease-in-out;

          .route {
            text-decoration: none;

            .title {
              display: flex;
              margin: 12px 0;
              height: 44px;
              opacity: 0.3;
              transition: opacity 0.8s;

              &.active {
                opacity: 1;
              }

              .number {
                display: flex;

                .frame {
                  margin: 0 2px;
                  height: 100%;
                  width: 24px;

                  .item {
                    margin: 0 auto;
                    border: 6px solid #f5f1ed;
                    border-radius: 12px;
                    height: 100%;
                    width: 100%;
                    transition: border 0.8s ease-in-out, width 0.8s ease-in-out;
                  }

                  .shapeshift {
                    width: 0;
                    border: 3px solid #ffca3a;
                  }
                }
              }

              .text {
                padding-left: 8px;
                width: 65%;
                line-height: 44px;
                text-align: left;
              }
            }
          }
        }

        .social {
          display: flex;
          justify-content: space-evenly;
          position: absolute;
          bottom: 0;
          transform: translateY(-50%);
          width: 100%;

          .link {
            border-radius: 16px;
            height: 32px;
            width: 32px;
            background-color: #fff;
          }
        }
      }

      .items {
        display: flex;
        overflow: hidden;
        position: relative;
        height: 100%;
        width: calc(100% - 338px);
      }
    }

    .footer {
      position: relative;
      height: 20%;
      width: 100%;

      .social {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15%;

        .link {
          border-radius: 16px;
          height: 32px;
          width: 32px;
          background-color: #fff;
        }
      }
    }
  }
}

/* Router-view transitions */
.slide-fade-up-enter-active,
.slide-fade-up-leave-active,
.slide-fade-down-enter-active,
.slide-fade-down-leave-active {
  transition: all 0.8s ease-in-out;
}

.slide-fade-up-enter-from,
.slide-fade-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-fade-up-leave-to,
.slide-fade-down-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

//Menu Overlay animations
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// Mobile navigation slide up
@keyframes slideUp {
  100% {
    bottom: 0;
  }
}

// Mobile social buttons slide up & scale
@keyframes slideUpAndScale {
  0% {
    top: 150%;
  }

  50% {
    top: 50%;
    transform: translate(-50%, -50%) scale(0.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    top: 50%;
  }
}

// Menu button animations
@keyframes upperCross {
  25% {
    width: 1px;
  }
  50% {
    width: 1px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  75% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  100% {
    top: 50%;
    width: 36px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes lowerCross {
  25% {
    width: 1px;
  }
  50% {
    width: 1px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  75% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    top: 50%;
    width: 36px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes upperCrossReverse {
  0% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 36px;
  }
  25% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  50% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 1px;
  }
  75% {
    top: 33%;
    width: 1px;
  }
  100% {
    width: 36px;
  }
}

@keyframes lowerCrossReverse {
  0% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 36px;
  }
  25% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  50% {
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 1px;
  }
  75% {
    top: 66%;
    width: 1px;
  }
  100% {
    width: 36px;
  }
}
</style>