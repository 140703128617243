<template>
  <div class="Home">
    <h1 class="header">List of the current projects</h1>
    <router-link to="/portfolio/simple">Portfolio</router-link>
    <br />
  </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>


<style lang="scss">
</style>